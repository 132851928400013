<template>
  <section class="section welcome">
    <div class="container welcome__container">
      <img src="@/assets/images/logo.svg" alt="logotype" class="welcome__logo">
      <h2 class="welcome__title">Welcome to our technological agency of mobile marketing!</h2>
      <h6 class="welcome__subtitle">
        We are here to help businesses connect with their audiences
        through innovative and effective mobile marketing strategies.
      </h6>
    </div>
  </section>
</template>
  
<script>
export default {
  name: 'Welcome'
}
</script>
  
<style lang="scss" scoped>
.welcome {
  height: 650px;
  background: url(@/assets/images/welcome_background_l.svg) no-repeat;
  background-size: cover;

  @media (max-width: $screen-l) {
    background: url(@/assets/images/welcome_background_m.svg) right no-repeat;
    background-size: cover;
    height: 614px;
  }

  @media (max-width: $screen-xs) {
    background: url(@/assets/images/welcome_background_s.svg) right no-repeat;
    background-size: cover;
    height: 548px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    margin: 40px 0 80px;

    @media (max-width: $screen-l) {
      margin: 40px 0 20px;
      width: 90px;
    }

    @media (max-width: $screen-xs) {
      margin: 40px 0;
    }
  }

  &__title {
    font-family: $font-work-sans;
    font-size: 46px;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 24px;
    max-width: 900px;

    @media (max-width: $screen-l) {
      max-width: 680px;
      font-size: 24px;
      line-height: 28px;
    }

    @media (max-width: $screen-xs) {
      font-size: 28px;
      line-height: 33px;
      max-width: 288px;
    }
  }

  &__subtitle {
    font-family: $font-inter;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    max-width: 840px;

    @media (max-width: $screen-l) {
      max-width: 680px;
      font-size: 20px;
      line-height: 24px;
    }

    @media (max-width: $screen-xs) {
      max-width: 288px;
    }
  }
}
</style>
  