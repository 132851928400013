<template>
  <main>
    <Welcome />
    <Services />
    <Support />
    <Choice />
    <Contacts />
    <Footer />
  </main>
</template>

<script>
import Welcome from '@/components/Welcome.vue';
import Services from '@/components/Services.vue';
import Support from '@/components/Support.vue';
import Choice from '@/components/Choice.vue';
import Contacts from '@/components/Contacts.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Welcome,
    Services,
    Support,
    Choice,
    Contacts,
    Footer
  }
}
</script>
