<template>
  <section class="section support">
    <div class="container support__container">
      <BaseTitle>Customer Support</BaseTitle>
      <p class="support__text">
        Our goal is to provide the best possible customer support to our clients.
        We understand the importance of timely and effective communication,
        and we have a dedicated support team available to answer any questions or concerns that may arise.
      </p>
    </div>
  </section>
</template>
  
<script>
import BaseTitle from '@/components/Base/BaseTitle.vue';

export default {
  name: 'Support',
  components: {
    BaseTitle
  }
}
</script>
  
<style lang="scss" scoped>
.support {
  background-color: var(--color-dark-green);
  padding: 50px 16px 100px;

  @media (max-width: $screen-l) {
    padding: 20px 16px 40px;
  }

  @media (max-width: $screen-xs) {
    padding: 12px 16px 24px;
  }

  &__text {
    margin-top: 40px;
    font-family: $font-inter;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: var(--color-white);

    @media (max-width: $screen-l) {
      margin-top: 20px;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
</style>
