<template>
  <h3 
    :class="{ 'section-title_no-decor': isNoDecor }" 
    :style="{ color: color }" 
    class="section-title"
  >
    <slot></slot>
  </h3>
</template>
  
<script>
export default {
  name: 'BaseTitle',
  props: {
    isNoDecor: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'var(--color-white)',
    },
  }
}
</script>
  
<style lang="scss" scoped>
.section-title {
  display: inline-flex;
  align-items: center;
  font-family: $font-work-sans;
  font-size: 38px;
  line-height: 45px;
  text-transform: uppercase;

  @media (max-width: $screen-l) {
    font-size: 24px;
    line-height: 28px;
  }

  &::before {
    content: '';
    display: inline-flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 16px;
    background-color: var(--color-blue);
  }

  &_no-decor {
    &::before {
      display: none;
    }
  }
}
</style>
  