<template>
  <section class="section contacts">
    <div class="container contacts__container">
      <p class="contacts__text">
        Thank you for considering our agency for your mobile marketing needs. We
        would be honored to help you connect with your audience in a meaningful
        way.
      </p>
      <BaseTitle is-no-decor class="contacts__title">Contact us today to learn more</BaseTitle>
    </div>
  </section>
</template>
  
<script>
import BaseTitle from '@/components/Base/BaseTitle.vue';

export default {
  name: 'Contacts',
  components: {
    BaseTitle,
  },
};
</script>
  
<style lang="scss" scoped>
.contacts {
  padding: 100px 16px;
  background-color: var(--color-white);

  @media (max-width: $screen-l) {
    padding: 40px 16px;
  }

  @media (max-width: $screen-xs) {
    padding: 16px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    isolation: isolate;
    border-radius: 32px;
    background: url(@/assets/images/banner_background.svg) right no-repeat;
    background-size: cover;

    @media (max-width: $screen-xs) {
      padding: 30px 16px;
    }
  }

  &__text {
    max-width: 730px;
    margin-bottom: 160px;
    font-family: $font-inter;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: var(--color-white);

    @media (max-width: $screen-l) {
      min-width: 100%;
      margin-bottom: 124px;
      font-size: 20px;
      line-height: 24px;
    }

    @media (max-width: $screen-xs) {
      margin-bottom: 48px;
      text-align: center;
    }
  }

  &__title {
    @media (max-width: $screen-xs) {
      text-align: center;
    }
  }
}
</style>
  