<template>
  <p 
    :style="{ color: color }" 
    class="section-text"
  >
    <slot></slot>
  </p>
</template>
  
<script>
export default {
  name: 'BaseText',
  props: {
    color: {
      type: String,
      default: 'var(--color-gray)'
    }
  }
}
</script>
  
<style lang="scss" scoped>
.section-text {
  font-family: $font-inter;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: $screen-l) {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
  