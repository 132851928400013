<template>
  <section class="section services">
    <div class="container services__container">
      <BaseTitle>Services</BaseTitle>
      <div class="services__items">
        <div 
          v-for="item in items" 
          :key="item.title" 
          class="services__item"
        >
          <BaseSubtitle>{{ item.title }}</BaseSubtitle>
          <BaseText color="var(--color-gray)">
            {{ item.text }}
          </BaseText>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
import BaseTitle from '@/components/Base/BaseTitle.vue';
import BaseSubtitle from '@/components/Base/BaseSubtitle.vue';
import BaseText from '@/components/Base/BaseText.vue';

export default {
  name: 'Services',
  components: {
    BaseTitle,
    BaseSubtitle,
    BaseText
  },
  data() {
    return {
      items: [
        {
          title: 'Mobile App Development',
          text: `Our team of experienced developers can create custom mobile apps 
            tailored to your business needs, including feature-rich iOS and Android apps.`
        },
        {
          title: 'Mobile Advertising',
          text: `We can help you reach your target audience through mobile advertising campaigns, 
            including mobile display ads, in-app ads, and mobile video ads.`
        },
        {
          title: 'SMS Marketing',
          text: `Text message marketing is an effective way to reach consumers directly on their mobile devices.
            We can help you create and execute SMS marketing campaigns that drive engagement and conversions.`
        },
        {
          title: 'Mobile Analytics',
          text: `Our analytics experts can help you track and analyze mobile user behavior,
              giving you valuable insights into how to optimize your mobile marketing efforts.`
        }
      ]
    }
  },
}
</script>
  
<style lang="scss" scoped>
.services {
  padding-bottom: 50px;
  background-color: var(--color-dark-green);

  @media (max-width: $screen-l) {
    padding-bottom: 20px;
  }

  @media (max-width: $screen-xs) {
    padding-bottom: 12px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 40px;
    background: var(--color-transparent-blue);
    backdrop-filter: blur(75px);
    border-radius: 32px;
    margin-top: -182px;

    @media (max-width: $screen-l) {
      margin-top: -346px;
      padding: 20px;
      gap: 20px;
    }

    @media (max-width: $screen-xs) {
      margin-top: -112px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% / 2 - 10px);
    padding: 30px;
    gap: 64px;
    background: var(--color-dark-green);
    border-radius: 20px;

    @media (max-width: $screen-l) {
      padding: 16px;
      gap: 66px;
    }

    @media (max-width: $screen-xs) {
      width: 100%;
      gap: 17px;
    }
  }
}
</style>
  