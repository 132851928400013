<template>
  <footer class="footer">
    <div class="container footer__container">
      <img src="@/assets/images/logo.svg" alt="logotype" class="footer__logo">
      <a href="mailto: contact@udmprocessing.com" class="footer__text">contact@udmprocessing.com</a>
      <span class="footer__text">2023</span>
    </div>
  </footer>
</template>
  
<script>
export default {
  name: 'Footer'
}
</script>
  
<style lang="scss" scoped>
.footer {
  padding: 50px 16px;
  background-color: var(--color-dark-green);

  @media (max-width: $screen-l) {
    padding: 30px 16px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $screen-xs) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__logo {
    @media (max-width: $screen-l) {
      width: 102px;
    }
  }

  &__text {
    font-family: $font-inter;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-white);

    @media (max-width: $screen-l) {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
  