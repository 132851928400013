<template>
  <section class="section choice">
    <div class="container choice__container">
      <BaseTitle color="var(--color-dark-green)">
        Why Choose Us
      </BaseTitle>
      <div class="choice__items">
        <div 
          v-for="item in items" 
          :key="item.title" 
          class="choice__item"
        >
          <div class="choice__item__content">
            <img 
              :src="require(`@/assets/images/${item.img}.svg`)" 
              alt="icon" 
              class="choice__item__icon"
            >
            <BaseSubtitle color="var(--color-dark-green)">
              {{ item.title }}
            </BaseSubtitle>
          </div>
          <BaseText 
            class="choice__item__text" 
            color="var(--color-dark-green)"
          >
            {{ item.text }}
          </BaseText>
        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
import BaseTitle from '@/components/Base/BaseTitle.vue';
import BaseSubtitle from '@/components/Base/BaseSubtitle.vue';
import BaseText from '@/components/Base/BaseText.vue';

export default {
  name: 'Choice',
  components: {
    BaseTitle,
    BaseSubtitle,
    BaseText
  },
  data() {
    return {
      items: [
        {
          title: 'Experience',
          img: 'experience',
          text: `We have a proven track record of success in the mobile marketing industry,
            with years of experience helping businesses of all sizes connect with their target audience.`
        },
        {
          title: 'Innovation',
          img: 'innovation',
          text: `Our team stays up-to-date with the latest mobile marketing trends and technology,
            allowing us to create cutting-edge strategies that drive results`
        },
        {
          title: 'Customization',
          img: 'customization',
          text: `We understand that every business has unique marketing needs,
            which is why we offer customized solutions tailored to each client's specific goals.`
        },
        {
          title: 'Results',
          img: 'results',
          text: `Our ultimate goal is to help our clients achieve their desired outcomes.
            We measure success by the results we deliver, whether that's increased engagement, conversions, or revenue`
        }
      ]
    }
  },
}
</script>
  
<style lang="scss" scoped>
.choice {
  padding: 100px 16px;
  background-color: var(--color-light-green);

  @media (max-width: $screen-l) {
    padding: 40px 16px;
  }

  @media (max-width: $screen-xs) {
    padding: 16px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: $screen-l) {
      gap: 20px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: $screen-xs) {
      gap: 8px;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 30px;
    gap: 14px;
    width: 100%;
    background: var(--color-white);
    border-radius: 20px;

    @media (max-width: $screen-l) {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
    }

    &__content {
      flex-grow: 1;
    }

    &__icon {
      margin-bottom: 12px;

      @media (max-width: $screen-l) {
        width: 58px;
      }
    }

    &__text {
      max-width: 630px;

      @media (max-width: $screen-l) {
        max-width: 100%;
      }
    }
  }
}
</style>
