<template>
  <h5 
    :style="{ color: color }" 
    class="section-subtitle"
  >
    <slot></slot>
  </h5>
</template>
  
<script>
export default {
  name: 'BaseSubtitle',
  props: {
    color: {
      type: String,
      default: 'var(--color-white)',
    },
  }
}
</script>
  
<style lang="scss" scoped>
.section-subtitle {
  font-family: $font-work-sans;
  font-size: 26px;
  line-height: 30px;

  @media (max-width: $screen-l) {
    font-size: 22px;
    line-height: 26px;
  }
}
</style>
  